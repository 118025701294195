<template>
  <tr :class="rowClass">
    <td v-if="draggableTableItems">
      <i class="fa fa-unsorted"></i>
    </td>
    <template v-for="item in rowInfoWithoutSpecFields">
      <td v-if="item.key === 'type'">
        <div
            class="flow-line"
            :style="`backgroundColor: ${flowColor}; width: ${flowWidth}; left: ${flowLeft}`"
            data-toggle="tooltip"
            data-placeholder="top"
            :data-html="false"
            data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner smaller text-left pre-line"></div></div>'
            :data-title="ruleHint"
        />
        <div :style="rowStyle(item.data)" :class="`${rowData.primary ? 'font-weight-bold': ''} text-nowrap`">
          {{item.data}}
          <template v-if="rowData.secondaryRule">
            <i
                class="fa fa-info-circle d-inline-block mt-1 ml-1 text-info"
                data-toggle="tooltip"
                data-placeholder="top"
                :data-html="false"
                data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner smaller text-left pre-line"></div></div>'
                :data-title="ruleHint"
            />
          </template>
          <template v-else-if="rowData.primary">
            <br><span class="d-block">(Primary)</span>
          </template>
        </div>
      </td>
      <td v-else-if="item.key === 'amount'">
        <div class="d-flex flex-row justify-content-center">
          <ul class="items-list">
            <template v-if="Array.isArray(item.data)">
              <li v-for="dataItem in item.data" :key="dataItem">{{dataItem}}</li>
            </template>
            <template v-else>
              <li>{{item.data}}</li>
            </template>
          </ul>
          <template v-if="rowData.warnings.length">
            <i
              class="fa fa-warning d-inline-block mt-1 ml-1 text-warning"
              data-toggle="tooltip"
              data-placeholder="top"
              :data-html="false"
              data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left pre-line"></div></div>'
              :data-title="rowWarningText"
              @click="$emit('dismissWarning', rowData.id)"
            />
          </template>
        </div>
      </td>
      <td v-else-if="item.key==='sponsoringProductData' || item.key === 'bonusProductData'" class="text-left">
        <div>
          <span :style="rowStyle(item.data)" class="mainInfo"><a :href="item.data.link" target="_blank">{{item.data.mainInfo || item.data.upc}}</a></span>
          <div class="description">{{item.data.description}}</div>
          <template v-if="presale && !rowData.approved && (item.key === 'bonusProductData')">
            <span class="text-warning small">Not Approved</span>
          </template>
        </div>
      </td>
      <td v-else-if="item.key === 'street_date'">
        <div>
          <template v-if="street_date === 'Invalid date'">
            <span class="text-warning small" >Not set</span>
          </template>
          <template v-else>
            <div>{{street_date}}</div>
            <span v-if="item.data.overriden" class="text-warning small" >Overridden</span>
          </template>
        </div>
      </td>
    </template>
    <action-buttons
      :id="rowData.id"
      @edit="$emit('edit', rowData.id)"
      @remove="$emit('remove', rowData.id)"
      :allowDeleteAction="allowDeleteAction"
    />
  </tr>
</template>

<script>
import actionButtons from "./actionButtons";
import {capitalize, get} from "lodash";
import moment from "moment";

export default {
  name: "tableRow",
  props: ["rowData", "draggableTableItems", "allowDeleteAction", "promotion"],
  components: {
    "action-buttons": actionButtons
  },

  computed: {
    presale() {
      return this.rowData && this.rowData.presale
    },
    street_date() {
      return this.$moment.tz(this.rowData.street_date.date, this.rowData.street_date.street_timezone).format('lll');
    },
    rowInfoWithoutSpecFields() {
      let ignoredKeys = ['id', 'primary', 'presale', 'ruleRaw', 'approved', 'external', 'warnings'];
      if (!this.presale) {
        ignoredKeys = [...ignoredKeys, 'street_date']
      }

      const keys = Object.keys(this.rowData).filter(
        rowKey => !ignoredKeys.includes(rowKey)
      );

      return keys.map(key => ({data: this.rowData[key], key}));
    },
    rowClass() {
      return get(this.rowData, 'warnings', []).length ? 'table-warning' : '';
    },
    rowWarningText() {
      if (this.rowData && this.rowData.warnings) {
        const suffix = 'Click to Acknowledge'
        let res = '';
        res = this.rowData.warnings.map((item) => `${(this.rowData.warnings.length > 1) ? '• ' : ''}${item}`).join('\n');
        return `${res}\n\n${suffix}`;
      }

      return '';
    },
    ruleHint() {
      if (!this.rowData.secondaryRule) {
        const dates = ['activation_start_datetime_with_timezone', 'activation_end_datetime_with_timezone', 'claim_end_datetime_with_timezone']
            .map(item => this.promotion[item].split('T')[0]);

        return [
            'Rule is active for the entire promotion duration:', '\n',
            'Activation Start Date: ', dates[0], '\n',
            'Activation End Date: ', dates[1], '\n',
            'Claim End Date: ', dates[2],
            ].join('');
      }

      const {activationStartDate, activationEndDate, claimEndDate} = this.rowData.secondaryData;

      return [
        capitalize(get(this.rowData.secondaryData, 'importance')), ' rule \n',
        'Activation Start Date: ', activationStartDate, '\n',
        'Activation End Date: ', activationEndDate, '\n',
        'Claim End Date: ', claimEndDate,
      ].join('');
    },

    promotionDuration() {
      const {activation_start_datetime, activation_end_datetime} = this.promotion;
      return moment(activation_end_datetime).diff(moment(activation_start_datetime), 'days');
    },

    flowColor() {
      const mainColor = '#00ff0e';
      const secondaryColors = {
        main: '#6b6bff',
        additional: '#6bb9ff',
        unspecified: '#ccc',
      }

      return this.rowData.secondaryRule ? secondaryColors[this.rowData.secondaryData.importance] || secondaryColors.unspecified : mainColor;
    },

    flowWidth() {
      if (!this.rowData.secondaryRule) {
        return '100%';
      }

      const {activationStartDate, activationEndDate} = this.rowData.secondaryData;
      const ruleRuleDuration = moment(activationEndDate).diff(moment(activationStartDate), 'days');
      const diff = (ruleRuleDuration / this.promotionDuration) * 100;

      return `${(diff > 1) ? `${diff}%` : '5px'}`;
    },

    flowLeft() {
      if (!this.rowData.secondaryRule) {
        return '0';
      }

      const {activationStartDate} = this.rowData.secondaryData;
      const diff = moment(activationStartDate).diff(moment(this.promotion.activation_start_datetime), 'days');

      return `${(diff / this.promotionDuration) * 100}%`;
    }
  },
  methods: {
    rowStyle(item) {
      return (item.deleted || item === '< Invalid >') ? 'color: red;' : '';
    }
  }
};
</script>

<style scoped lang="scss">
.thead {
  background: #ebebeb;
}

.table .thead th {
  vertical-align: middle;
  border-bottom: none;
}

.fa-unsorted {
  font-size: 16px;
}

span.mainInfo {
  display: block;
  width: 100%;
}

.description {
  color: rgb(175, 174, 174);
  font-size: 10px;
}

ul.items-list li {
  list-style-type: none;
  padding-left: 0;
  white-space: nowrap;
}

tbody {
  tr {
    position: relative;
    .flow-line {
      content: '';
      position: absolute;
      top: 0;
      height: 5px;
    }
  }
}
</style>
