<template>
<div v-change-outside.jquery="{ name: 'changedBranch', handler: changedBranch }">

  <label for="one_tz" class="no-select mt-1 d-flex align-items-center">
    <input type="checkbox" v-model="oneTz" id="one_tz" class="mr-1"/>
    Use one timezone for all dates
  </label>

  <template v-for="field in fieldsList">
    <template v-if="field.key.includes('datetime') && field.value">
      <div :key="field.key"
           class="form-group">
        <label :for="field.key">
          {{field.label}}
        </label>

        <input type="hidden"
               :value="field.value"
               :name="field.name"
               :id="field.key"/>

        <datepicker :value="customFormatter(field.value)"
                    @input="(value) => onChangeDate(value, field.key)"
                    format="dd/MM/yyyy"
                    :bootstrap-styling="true"
                    :disabled-dates="disabledDates[field.key]"
                    :input-class="errors[field.key] ? 'form-control date-picker-input is-invalid' : 'form-control date-picker-input'"/>
          <span v-if="errors[field.key]" class="text-danger small d-inline-block mt-1">{{errors[field.key]}}</span>
      </div>
    </template>
    <template v-else-if="field.key.includes('timezone')">
      <div v-if="field.key === 'activation_start_timezone' || !oneTz"
          :key="field.key"
           class="form-group">

        <label :for="field.key">
          {{oneTz ? 'Timezone' : field.label}}
        </label>

        <select :name="field.name"
                :id="field.key"
                :class="errors[field.key] ? 'form-control is-invalid' : 'form-control'"
                @change.prevent="(event) => changeTimezone(event.target.value, field.key)"
                v-model="field.value">
          <option  v-for="item in timezoneList"
                   :selected="item.value === field.value"
                   :key="item.value"
                   :value="item.value"
                   v-text="item.label"/>
        </select>

        <span v-if="errors[field.key]" class="text-danger small">{{errors[field.key]}}</span>
      </div>
      <template v-else>
        <input type="hidden"
               :value="field.value"
               :name="field.name"/>
      </template>

      <hr>
    </template>
  </template>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import tzList from './tz.json';

export default {
  props: {
    formName: {
      required: true,
      type: String,
    },
    fields: {
      required: true,
      type: String,
    },
    displayMessage: {
      required: false,
      type: [String, Object],
    },
    presale: {
      required: false,
      type: Boolean,
      default: false,
    },
    branchTz: {
      required: true,
      type: String,
    },
  },

  components: {
    Datepicker
  },

  data() {
    return {
      fieldsNormalized: JSON.parse(this.fields),
      errors: JSON.parse(this.displayMessage),
      oneTz: false,
      tz: [...new Set(Object.keys(tzList).map(key => tzList[key]))],
      defaultTz: 'Etc/UTC',

      FIELD_NAME: {
        activation_start_datetime: 'Activation Start Date',
        activation_start_timezone:'Activation Start Timezone',
        activation_end_datetime: 'Activation End Date',
        activation_end_timezone: 'Activation End Timezone',
        claim_end_datetime: 'Claim End Date',
        claim_end_timezone: 'Claim End Timezone',
        support_end_datetime: 'Support End Date',
        support_end_timezone: 'Support End Timezone',
      },

      minDate: new Date(this.$moment().add(-1, 'y').startOf('day').format()),
    };
  },
  mounted() {
    this.initTimezones();
    this.initDateTimes();

    setTimeout(() => {
      this.checkIsValidTime();
    }, 600);
  },

  computed: {
    fieldsList() {
      return Object.keys(this.fieldsNormalized).map((key) => ({
          key,
          name: `${this.formName}[${key}]`,
          value: this.fieldsNormalized[key],
          required: true, //todo: add presale logic
          label: this.FIELD_NAME[key] || key,
        })
      );
    },

    timezones() {
      return Object.keys(this.fieldsNormalized).filter(key => key.includes('timezone'));
    },

    dateTimes() {
      return Object.keys(this.fieldsNormalized).filter(key => key.includes('datetime'));
    },

    timezoneList() {
      const timeZones = this.$moment.tz.names(true).filter(key => this.tz.includes(key));

      let offsetTmz = timeZones.map(tz => {
        const zone = this.$moment.tz(tz);
        const prefix = RegExp(/^-|\d/).test(zone.format('z')) ? '' : `${zone.format('z')} - `;

        return {
          offset: this.$moment.tz(tz)._offset,
          label: `(GMT ${this.$moment.tz(tz).format('Z')}) ${prefix}${tz.replace(/_/g,' ')}`,
          value: tz
        }
      });

      return offsetTmz.sort((a,b) => (a.offset - b.offset));
    },

    guess() {
      return this.$moment.tz.guess(true);
    },

    disabledDates() {
      return {
        activation_start_datetime: {
          to: this.minDate,
        },
        activation_end_datetime: {
          to: this.minDate
        },
        claim_end_datetime: {
          to: this.minDate,
        },
        support_end_datetime: {
          to: this.minDate,
        }
      }
    },

  },

  watch: {
    oneTz(value) {
      if (value) {
        const sample = this.fieldsNormalized[this.timezones[0]];

        this.timezones.forEach((key) => {
            this.fieldsNormalized[key] = sample;
            this.updateDateTimeWithTimezone(sample, key);
        })
      }
    },
  },

  methods: {
    customFormatter(date) {
      return this.$moment(date.replace(/T.*$/,'')).format()
    },

    checkIsValidTime() {
      if (this.dateChanged) {
        return;
      }

      let isValid = true;

      const ff = JSON.parse(this.fields);

      this.dateTimes.forEach((key) => {
        if (ff[key] && this.$moment(this.fieldsNormalized[key]).format('x') !== this.$moment(ff[key]).format('x')) {
          isValid = false;
        }
      });

      this.$nextTick(() => {
        if (!isValid) {
          alert("Please verify the promotion's dates and timezones are set correctly");
        }
      })
    },

    changedBranch (e) {
      const targetId = 'promotion_branch_id';

      if (e.target.id === targetId) {
        const $tz = $(`#${targetId} option:selected`);

        if ($tz && $tz.data('timezone')) {
          const tz = $tz.data('timezone');

          this.oneTz = true;

          this.timezones.forEach(key => {
            this.fieldsNormalized[key] = tz;
            this.updateDateTimeWithTimezone(tz, key);
          });
        }
      }
    },

    initTimezones() {
      this.timezones.forEach(key => {
        let value = this.fieldsNormalized[key];
        if (!value) {
          this.fieldsNormalized[key] = this.defaultTz;
        }
      });

      const fields = this.timezones.map(key => this.fieldsNormalized[key]);

      this.oneTz = fields.every(item => item === fields[0]);
    },

    initDateTimes() {
      const now = this.$moment.tz(new Date(), this.guess);

      this.dateTimes.forEach(key => {
        let value = this.fieldsNormalized[key];
        if (!value) {
          this.fieldsNormalized[key] = now.add('week', 1).format();
        } else {
          this.changeDate(value, key, true);
        }
      });
    },

    onChangeDate(value, fieldName) {
      this.changeDate(value, fieldName);
      this.dateChanged = true;
    },

    changeDate(value, fieldName, useFullTime) {
      const key = fieldName.replace(/datetime$/, 'timezone');
      const tz = this.fieldsNormalized[key];
      let date = this.$moment(value).format();

      if (!useFullTime) {
        date = date.replace(/T.*$/,'');
      }

      this.fieldsNormalized[fieldName] = this.$moment.tz(date, tz).format();
    },

    changeTimezone(value, fieldName) {
      if (this.oneTz) {
        this.timezones.forEach(key => {
          this.fieldsNormalized[key] = value;
          this.updateDateTimeWithTimezone(value, key);
        })
      } else {
        this.fieldsNormalized[fieldName] = value;
        this.updateDateTimeWithTimezone(value, fieldName);
      }
    },

    updateDateTimeWithTimezone(value, fieldName, useFullTime) {
      const key = fieldName.replace(/timezone$/, 'datetime');
      const date = this.fieldsNormalized[key].replace(/T.*$/,'');

      this.fieldsNormalized[key] = this.$moment.tz(date, value).format();
    },
  }
}
</script>
